import { LitElement } from 'lit';

// import { choose } from 'lit/directives/choose.js';
import { until } from 'lit/directives/until.js';
import { live } from 'lit/directives/live.js';
import { ref } from 'lit/directives/ref.js';

import { msg, str } from '@lit/localize';
import { NorthStarElement, html, define } from '../../northstar';
import { observer as resizeObserver } from '../../utils/resize';
import { debounce, isTouch } from '../../utils';
import { setLocale, getLang } from '../../utils/locale';
import { NAMESPACE } from '../../constants';
import StatesMap from './states';
import './slider';
import '../icon';

import { createApi } from './api';

import styles from './calculator.css';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // Use these options to round to whole numbers
  // minimumFractionDigits: 0, // 1200.10 => $1,200.1
  maximumFractionDigits: 0, // 1500.99 => $1,501
});

const toUSD = (value) => currencyFormatter.format(+value);

function template(elem) {
  const { state, statesList: states } = elem;

  const scores = [
    { label: msg('Exceptional (750-850)'), value: msg('Exceptional (750-850)') },
    { label: msg('Very good (700-749)'), value: msg('Very good (700-749)') },
    { label: msg('Good (660-699)'), value: msg('Good (660-699)') },
    { label: msg('Fair (580-659)'), value: msg('Fair (580-659)') },
    { label: msg('Needs work (300-579)'), value: msg('Needs work (300-579)') },
    { label: msg('Unknown score'), value: msg('Unknown score') },
  ];

  const secureOptions = [
    { label: msg('Yes, I own a car without payments'), value: 'yes' },
    { label: msg('Yes, I’m still making payments'), value: 'has_car_payments' },
    { label: msg('No, I don’t own a car'), value: 'no' },
    { label: msg('No, I lease a car'), value: 'lease' },
  ];

  let metadata;
  async function getMetaData() {
    if (metadata) {
      return metadata;
    }
    metadata = await elem.getStateMetaData(state);
    return metadata || {};
  }

  function onLoanDetailsChange(e) {
    const { target } = e;
    const { name, value } = target;
    elem.updateDetails({ [name]: value });

    elem.sendEvents(e, {
      [name]: value,
      event: 'change',
      target,
      ...target.dataset,
    });
  }

  function onLoanTypeChange(e) {
    const { target } = e;
    const name = target.getAttribute('data-name');
    const value = target.getAttribute('data-value');
    elem.updateDetails({ [name]: value });

    elem.sendEvents(e, {
      [name]: value,
      event: 'change',
      target,
      ...target.dataset,
    });
  }
  
  function onLoanTypeChangeRD(e) {
    const { target } = e;
    const name = target.getAttribute('data-name');
    const value = target.getAttribute('data-value');
    const fullvalue =  target.value;
    const elemName =  target.name;
    elem.updateDetails({ [name]: value });
    this.sendEvents(e, {
      [elemName]: fullvalue,
      event: 'change',
      label: elemName,
      attr3: fullvalue,
      target,
      section: 'calculator',
    });
  }

  function onAmountChanged(e) {
    const { target } = e;

    const { value } = e.detail;
    elem.updateDetails({ amount: `${value}` });

    target.dataset.event_tracking_label = `LoanAmount:${value}`;

    elem.debouncedSendEvents(e, {
      amount: value,
      event: 'change',
      target,
      ...target.dataset,
    });
  }

  function renderLoanAmounts(details) {
    if (!details) {
      const { loan_calc_initial_loan_amount} = elem;
      console.log(loan_calc_initial_loan_amount)
      return html`
        <div class="amount-container-preselect-label">${msg('Loan amount')}</div>
        <div class="amount-container-preselect${elem.inside_hero == "yes" ? ' hero' : ''}">
          <div class="current_amount">$${elem.loan_calc_initial_loan_amount}</div>
        </div>
        <div class="disclosure">${renderDisclosure()}</div>
      `;
    }

    const { amounts, amount: selected } = details;

    const amountsNum = amounts.map((a) => +a);
    const min = amountsNum[0];
    const max = amountsNum[amountsNum.length - 1];

    return html`
      <div class="amount-container">
        <div class="row">
          <div class="label">${msg('Loan amount')}</div>
          <div class="value"><div class="current_amount">${toUSD(+selected)}</div></div>
        </div>
        <div class="row-full">
          <div class="value">
            <oportun-loan-slider
              .datalist=${amountsNum}
              .min=${min}
              .max=${max}
              .value=${selected}
              @change=${onAmountChanged}
              data-event_tracking="change"
              data-event_tracking_label="Amount"
              data-event_tracking_section="calculator"
            >
            </oportun-loan-slider>
          </div>
        </div>
        <div class="row minmax">
          <div class="min">${toUSD(+min)}</div>
          <div class="max">${toUSD(+max)}</div>
        </div>
      </div>
      
    `;
  }

  function renderApr(details) {
    if (!details) {
      return '%';
    }
    const apr = details.apr || {};
    const { min, max } = apr;
    if (min === max) {
      return html`<span>${min}%</span>`;
    }
    return html` <span>${min}</span>-<span>${max}%</span> `;
  }

  function renderPayments(details) {
    if (!details) {
      return '$';
    }
    const payments = details.payments || {};
    const { min, max } = payments;
    if (min === max) {
      return html`<span>${toUSD(max)}</span>`;
    }
    return html`<span>${toUSD(min)}</span>-<span>${toUSD(max)}</span>`;
  }

  function renderDisclosure() {
    const details = elem.loanDetails;

    const disclosure = details && details.disclosure ? details.disclosure : '';

    return html` <div class="disclosure-text">${disclosure}</div> `;
  }

  async function getCreditScores() {
    const metadata = await getMetaData();
    const { secure, score: selected = '' } = elem;
    
    if (!metadata.requireScore) {
      return '';
    }

    if (metadata.hasSPL && secure === null) {
      return '';
    }
   
    return html`
      <fieldset class="$!{selected ? 'filled' : ''}" >
        <legend id="score-label" aria-hidden="${!selected}">${msg('Estimated credit score')}</legend>
        <select
          name="score"
          id="score"
          @change=${elem.onChange}
          data-event_tracking="change"
          data-event_tracking_label="Credit score"
          data-event_tracking_section="calculator"
          aria-labelledby="score-label"
        >
          <option value="" ?selected=${!selected} ?disabled=${selected}>
            ${msg('Estimated credit score')}
          </option>
          ${scores.map(
    ({ value, label }) => html`
              <option value="${value}" ?selected="${selected === value}">${label}</option>
            `,
  )}
        </select>
      </fieldset>
    `;
  }

  async function getSecureLoanOptions() {
    const { secure: selected = '' } = elem;
    const metadata = await getMetaData();

    if (!metadata.hasSPL) {
      return '';
    }
    return html`
      <fieldset class="${selected ? 'filled' : ''}" >
        <legend id="secure-label" aria-hidden="${!selected}">${msg('Do you own a car?')}</legend>
        <select
          name="secure"
          id="secure"
          class="has_car"
          id="creditscorenew"
          @change=${elem.onChange}
          data-event_tracking="change"
          data-event_tracking_label="Secured"
          data-event_tracking_section="calculator"
          aria-labelledby="secure-label"
        >
          <option value="" ?selected=${!selected} ?disabled=${selected}>
            ${msg('Do you own a car?')}
          </option>

          ${secureOptions.map(
    (opt) => html`
              <option value="${opt.value}" ?selected="${selected === opt.value}">
                ${opt.label}
              </option>
            `,
  )}
        </select>
      </fieldset>
    `;
  }
  
  function renderStateSelect(states) {
    let { state: selected, locked = false } = elem;
    let filteredStates = [];

    if (elem.AVAILABLE_STATES != '') {
      states.map((state) => {
        if (elem.AVAILABLE_STATES.indexOf(state) != '-1') {
          filteredStates.push(state);
        }
      });
    } else {
      filteredStates = states;
    }

    if( typeof elem.getCurrLocation != 'undefined' || elem.getCurrLocation != null ){
      let currLocation = elem.getCurrLocation;      
    }
    
    const sortedStates = filteredStates.sort((a, b) => a.localeCompare(b));

    return html`
      <fieldset class="${selected ? 'filled' : ''}">
        <legend id="state-label" aria-hidden="${!selected}">${msg('Where do you live?')}</legend>
        <select
          name="state"
          id="state"
          ?disabled=${locked}
          @change=${elem.onChange}
          data-event_tracking="change"
          data-event_tracking_label="States"
          data-event_tracking_section="calculator"
          aria-labelledby="state-label"
        >
          <option value="" ?selected=${!selected} ?disabled=${selected}>
            ${msg('Where do you live?')}
          </option>
          ${sortedStates.map(
    (stateName) => html`<option value=${stateName} ?selected=${selected === stateName}>
              ${stateName}
            </option>`,
  )}
        </select>
      </fieldset>
    `;
  }
  
  function renderLoanTypeSelector(details) {
    if (!details) {
      return '';
    }

    const { meta, type, secure, amounts } = details;
    
    if (!meta.hasSPL) {
      return '';
    }

    if (secure !== 'yes') {
      return '';
    }

    const { upl, spl } = meta;

    if (!(upl && spl)) {
      return '';
    }
    const amountsNum = amounts.map((a) => +a);
    const min = amountsNum[0];
    const max = amountsNum[amountsNum.length - 1];

    function renderButtons(){
      return html`
        <div class="row loan-types">
          <button
            class="upl-loan ${type !== 'spl' ? 'selected' : ''}"
            data-name="type"
            data-value="upl"
            data-event_tracking="Button click"
            data-event_tracking_label="type upl"
            data-event_tracking_section="calculator"
            @click=${onLoanTypeChange}
            ?selected=${type === 'upl'}
          >
            <span class="loan-type"> ${msg('Personal loan')} </span>
            <span class="range"> (${toUSD(upl.min)}-${toUSD(upl.max)}) </span>
          </button>
          <span class="or">${msg('or')}</span>
          <button
            class="spl-loan ${type === 'spl' ? 'selected' : ''}"
            data-name="type"
            data-value="spl"
            data-event_tracking="Button click"
            data-event_tracking_label="type spl"
            data-event_tracking_section="calculator"
            @click=${onLoanTypeChange}
            ?selected=${type === 'spl'}
          >
            <span class="loan-type"> ${msg(' Secured personal loan')} </span>
            <span class="range"> (${toUSD(spl.min)}-${toUSD(spl.max)}) </span>
          </button>
        </div>

        <div class="row banner ${type === 'spl' ? 'active' : ''}" aria-hidden="${type === 'spl'}">
          <div class="spl-loan-advantage">
            <div class="icon">
              <oportun-icon name="info"></oportun-icon>
            </div>
            <div class="text">
              ${msg(
                'Use your car title for better odds of getting a larger loan.',
              )}
            </div>
          </div>
        </div>
      `;
    }
    function renderRadioButtons(){
      return html`
        <div class="loan-types-legend" >${msg('Apply once and get two great options')}</div>
        <div class="loan-types radio">
          <input type="radio" id="upl" name="loan_type"
            class="upl-loan ${type !== 'spl' ? 'selected' : ''}"
            data-name="type"
            data-value="upl"
            value="personal_loan"
            data-event_tracking="change"
            data-event_tracking_label="radio_button"
            data-event_tracking_attr3="personal_loan"
            data-event_tracking_section="calculator"
            @change=${onLoanTypeChangeRD}
            ?selected=${type === 'upl'}
          >
          <label for="upl">
            <span class="loan-type"> ${msg('Personal loans')} </span>
            <span class="range"> ${msg('Max of ')} ${toUSD(upl.max)} </span>
          </label>
          <span class="or"></span>
            <input type="radio" id="spl" name="loan_type"
              class="spl-loan ${type === 'spl' ? 'selected' : ''}"
              checked="yes"
              data-name="type"
              data-value="spl"
              value="secure_personal_loan"
              data-event_tracking="change"
              data-event_tracking_label="radio_button"
              data-event_tracking_attr3="secure_personal_loan"
              data-event_tracking_section="calculator"
              @change=${onLoanTypeChangeRD}
              ?selected=${type === 'spl'}
            >
          <label for="spl">
            <span class="loan-type"> ${msg(' Secured personal loans')} </span>
            <span class="range"> ${msg('Max of ')} ${toUSD(spl.max)} </span>
          </label>
      
        </div>
        <div class="row banner ${type === 'spl' ? 'active' : ''}" aria-hidden="${type === 'spl'}">
          <div class="spl-loan-advantage">
            <div class="icon">
              <oportun-icon name="info"></oportun-icon>
            </div>
            <div class="text">
              ${msg(
                'Use your car title for better odds of getting a larger loan.',
              )}
            </div>
          </div>
        </div>
      `
    }

    function mainRender(){
      switch(elem.upl_spl_type){
        case 'buttons':
          return html`${renderButtons()}`
          break;
        case 'radio':
          return html`${renderRadioButtons()}`
          break;
      } 
    }

    return html` ${mainRender()}`
      
  }

  function renderHeader(details) {
    if (!details) {
      return '';
    }
    return html`
      <!-- <div class="row">
        <div class="caption">
          <div class="text">
            <slot name="${details.isSecure ? 'caption4' : 'caption3'}"></slot>
            <slot name="${details.isSecure ? 'caption4' : 'caption3'}"></slot>
          </div>
        </div>
      </div> -->
    `;
  }

  function paymentFrequency() {
    if (state === 'New Jersey') {
      return msg('once a month');
    }
    return msg('every two weeks');
  }

  function renderDetails() {
    const details = elem.loanDetails;
    if(details && elem.inside_hero == "yes" && !isTouch()){
      elem.parent.style.setProperty('grid-template-columns', '100%');
      if(elem.parent.firstElementChild.nodeName.toLowerCase() != "oportun-loan-calculator"){

        elem.getRootNode().host.style.setProperty('grid-area', 'c1');
        elem.parent.firstElementChild.style.setProperty('display', 'none');
        
      }
    }
    return html`
      <div class="loan-details-container${elem.inside_hero == "yes" ? ' hero' : ''}">
        ${renderHeader(details)} 

        ${renderLoanAmounts(details)}
            ${details
    ? html` 
          <div class="rows">    
              <div class="label">
                <strong>${msg('Pay ')} ${paymentFrequency()}</strong>
              </div>
              <div class="value">${renderPayments(details)}</div>
          </div>
          <div class="rows">    
              <div class="label">
                <strong>APR</strong>
              </div>
              <div class="value">${renderApr(details)}</div>
          </div>
          <div class="rows">    
            
              <div class="label">
                <strong>${msg('Number of payments')}</strong>
              </div>
              <div class="value">${details ? details.noOfPayments : '#'}</div>
            
          </div>
          <div class="rows hide-border-mobile">    
            
              <div class="label">
                <label for="duration"><strong>${msg('Length of loan')}</strong></label>
              </div>
              <div class="value">
                <span class="custom-select">
                  <select
                    name="duration"
                    id="duration"
                    @change=${onLoanDetailsChange}
                    data-event_tracking="change"
                    data-event_tracking_label="duration"
                    data-event_tracking_section="calculator"
                  >
                    ${details.durations.map(
(dur) => html`<option value=${dur} ?selected=${details.duration === dur}>
                        ${dur}
                      </option>`,
)}
                  </select>
                </span>
              </div>
            
          </div>
          <div class="loan-details-container-bottom">
            <div class="disclosure">${renderDisclosure()}</div>
            <div class="calltoaction">${renderCallToAction()}</div>
          </div>
          `
    : ''}
          </div>
        </div>
        
        
      </div>
    `;
  }

  function renderCaption() {
    const details = elem.loanDetails;

    async function getCaption() {
      const meta = await getMetaData();

      if (meta.hasSPL) {
        return '';
        //
        return html`
          <div class="caption">
            <div class="text"><slot name="caption1"></slot></div>
            <div class="image"><slot name="background"></slot></div>
          </div>
        `;
      }
    }
    if (details) {
      return '';
    }

    return until(getCaption(), () => '');
  }

  function renderCallToAction() {
    if (elem.disable_apply) {
      return '';
    }
    // TODO: hide apply button on outreach pages
    return html`
      <div class="apply-button">
        <a
          href="${elem.loan_calc_cta_url}"
          @mousedown=${elem.handleApplyBtn}
          class="button"
          data-event_tracking="button click"
          data-event_tracking_section="calculator"
          data-event_tracking_label="Start your loan application"
          >${elem.loan_calc_cta_text}</a>
        
      </div>
    `;
  }

  function renderRetailLoanTable(details){
    if ( !details ){
      return html ``;
    }
    const { topDisclosure, bottomDisclosure, rightDisclosure, amountFinanced, adminFees, latePaymentFee, returnedPaymentFee } = details;
    
    return html `
          <div class="retail-disclaimer">
            ${ topDisclosure }
          </div>
          <div class="loan-calc-table-of-concepts"> 
            <div class="col1 ">
              <div class="cell">${msg('Amount financed') }</div>
              <div class="cell">${msg('Administrative fees') }</div>
              <div class="cell">${msg('Late payment fee') }</div>
              <div class="cell">${msg('Returned payment fee') }</div>
            </div>
            <div class="col2 ">
              <div class="cell"> ${ amountFinanced } </div>
              <div class="cell"> ${ adminFees } </div>
              <div class="cell"> ${ latePaymentFee } </div>
              <div class="cell"> ${ returnedPaymentFee } </div>
            </div>
            <div class="col3 ">
              <div class="cell">
                ${ rightDisclosure }
              </div>  
            </div>
          </div>
          <div class="retail-disclaimer">
            ${ bottomDisclosure }
          </div>`;
  }
  function renderCalculator() {
    const details = elem.loanDetails;
    let tofade = 'no';
    if(  elem.pre_populate == 'yes' ){
       tofade = 'yes';      
    }
    return html`

      ${tofade == 'yes' ? html`<div class="spinner-bg"><div class="spinner-text">${msg('Loading')}</div><div class="spinner"></div></div>` : ''}
      <div class="calculator ${tofade == 'yes' ? 'animate-bottom' :''} "  style="${tofade == 'yes' ? 'display:none;': ''}">
        <div class="title">
          <slot name="title"></slot>
        </div>
        <div class="user-details">
          <div class="desc">
            <slot name="description"></slot>
          </div>

          ${renderCaption()}
          <div class="user-fields">
            <div class="field state">${renderStateSelect(states)}</div>
            <div class="field secure">${until(getSecureLoanOptions(), '')}</div>
            <div class="field credit-scores">${until(getCreditScores(), '')}</div>
          </div>
          <div></div>
          
          ${renderLoanTypeSelector(details)}
        </div>
        <div class="loan-details">${renderDetails()}</div>
      </div>
      <div name="retail-table" class="retail-table">${ (elem.loan_calc_style) ? renderRetailLoanTable(details) : '' }</div>
      
    `;
  }

  return html` ${states ? renderCalculator() : html`<div>loading...</div>`} `;
}

async function isMobile(){
  let hasTouchScreen = false;
  if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
  } else {
      const mQ = matchMedia?.("(pointer:coarse)");
      if (mQ?.media === "(pointer:coarse)") {
          hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
          hasTouchScreen = true; // deprecated, but good fallback
      } else {
          // Only as a last resort, fall back to user agent sniffing
          const UA = navigator.userAgent;
          hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
  }
  return hasTouchScreen;
}

export default class Calculator extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {
    statesList: { type: Object },
    parent: {type: Object },
    state: { type: String },
    score: { type: String },
    duration: { type: String },
    amount: { type: String },
    secure: { type: String },
    type: { type: String },
    loanDetails: { type: String },
    loantype: { type: String },
    locked: { type: String }, // lock to one state
    disable_apply: { type: Boolean },
    upl_spl_type: { type: String },
    loan_calc_style: { type: String },
    loan_calc_cta_text: {type:String},
    loan_calc_cta_url: {type:String},
    loan_calc_initial_loan_amount: {type:String},
    getCurrLocation: {type:String},
    pre_populate: {type: String},
    inside_hero: {type: String},
    CALC_DATA:{type:Object},
    MAXMIN_DATA:{type:Object},
    AVAILABLE_STATES:{type:Object},
    lang: {type: String},
  };

  constructor() {
    super();
    // slider fires too many events when dragged
    // debounce this once every second
    this.debouncedSendEvents = debounce((e, data) => {
      this.sendEvents(e, data);
    }, 1000);
  }

  getStateMetaData() {
    const { state } = this;
    return this.api.getStateMetaData(state);
  }

  async onChange(e) {
    const { target } = e;
    const { name, value } = target;
    if (name === 'state') {
      // reset when state changes
      this.score = null;
      this.secure = null;
      await this.fetchStateData(value)
      .then(() => {
        this.api = createApi(this.CALC_DATA, this.MAXMIN_DATA); 
      })
      .catch(error => {
        console.error(error);
      });
      delete this.loanDetails;
    }
    if (name === 'secure') {
      // reset when secure changes
      this.score = null;
      this.secure = null;

      delete this.loanDetails;
    }
    this[name] = value;

    await this.fetchLoanDetails();

    this.sendEvents(e, {
      [name]: value,
      event: 'change',
      label: name,
      target,
      section: 'calculator',
    });
  }

  handleApplyBtn(e) {
    this.sendAnalyticsEvent({
      target: e.currentTarget,
      originalEvent: e,
      data: {
        ...e.currentTarget.dataset,
      },
    });
  }

  async fetchStateData(state){
    const stateFile = this.stateSlug(state);

    let jsonURL = `${window.location.origin}/wp-content/uploads/loan_calc/${this.lang}/${stateFile}.json`;

    try{
      const response = await fetch(jsonURL);

      if (!response.ok) {
        throw new Error(`Failed to load JSON: ${response.status} ${response.statusText}`);
      }

      const jsonData = await response.json();

      if (!jsonData || typeof jsonData !== 'object') {
        throw new Error('Invalid JSON format');
      }
  
      this.CALC_DATA = jsonData;
    } catch(error){
      console.error('Error:', error);
    }

  }

  stateSlug(stateName) {
    let stateSlug = stateName.trim().toLowerCase(); 
    stateSlug = stateSlug.replace(/\s+/g, '-');
    stateSlug = stateSlug.replace(/[^a-z0-9-áéíóúüñ]/g, '');
    stateSlug = stateSlug.replace(/^-+|-+$/g, '');
    return stateSlug;
  }


  async fetchLoanDetails() {
    const { state, score, secure } = this;
    if (!state) {
      return;
    } 
    
    const { amount, duration, type } = this.loanDetails || {};
    try {
      const params = {
        state,
        score,
        secure,
        amount,
        duration,
        type,
      };
      const details = await this.api.getLoanDetails(params);
      this.loanDetails = details;
      return details;
    } catch (e) {
      this.loanDetails = null;
      return null;
    }
  }

  updateDetails(params) {
    Object.assign(this.loanDetails, params);
    this.fetchLoanDetails();
  }

  getStateNames() {
    return this.api.getStateNames();
  }

  updateBanner(node) {}

  sendEvents(e, data) {
    const { target = e.target } = data; // equivalent to data.target || e.target;

    // if no target, ignore
    if (!target) {
      return;
    }

    const { nodeName } = target;

    let targetNode = target;

    // if we get a custom element here, it most likely is a custom form control
    // NOTE: some browsers may report nodeName in lowercase
    if (nodeName.toUpperCase().startsWith(NAMESPACE.toUpperCase())) {
      // create a new input element and copy all attributes
      // from original element
      targetNode = document.createElement('input');
      targetNode.setAttribute('type', 'text');

      // all custom form controls have a value getter
      targetNode.setAttribute('value', target.value);

      for (const { name, value } of target.attributes) {
        targetNode.setAttribute(name, value);
      }

      // replace target with the new input element
      // NOTE: this new element is not attached to the DOM
      //        but seems to work fine for the analytics tracker
      data.target = targetNode;
    }

    // this.triggerEvent('change', { target, originalEvent: e, data });
    this.sendAnalyticsEvent({ target: targetNode, originalEvent: e, data });
  }

  triggetUpd(){
    if( typeof this.getCurrLocation != 'undefined' || this.getCurrLocation != null ){
      // console.log('trigger the function')
      const selectState = this.shadowRoot.querySelector('[name="state"]')
      selectState.value= this.getCurrLocation;
      selectState.dispatchEvent(new Event("change", { bubbles: true }));      
    }



  }

  processTealiumLocation(locationString){
    let ret ={};
    if(typeof locationString == 'string'){
      const locPieces = locationString.split(',');
      locPieces.forEach((piece)=>{
        let tmp = piece.split('=')
        ret[tmp[0]] = tmp[1]
      });
      return ret;
    }
  }

  attachedCallback(){
    
    this.parent = this.getRootNode().host.parentNode;
    this.inside_hero = this.getRootNode().host.getAttribute('inside_hero');
    this.lang = this.getRootNode().host.getAttribute('lang');
    this.AVAILABLE_STATES = JSON.parse(this.getRootNode().host.querySelector('#available_states').value);
    this.MAXMIN_DATA = JSON.parse(this.getRootNode().host.querySelector('#max_min_data').value);

    if(this.pre_populate == 'yes'){
      const currentLocation = () =>{
        if(typeof(utag_data.visitor_state_location) !== 'undefined'){
          let currLocation  = utag_data.visitor_state_location;
          if( typeof currLocation != 'undefined' || currLocation != null ){
            currLocation = this.processTealiumLocation(currLocation);
            if(currLocation.region_code == 'NLE'){
              this.getCurrLocation = 'California'; // this is for testing from Agu - Mex
            } else{
              this.getCurrLocation = StatesMap[currLocation.region_code]
            }
          }
          
          this.triggetUpd();
          let timeoutSecureDrop;
          clearTimeout(timeoutSecureDrop);
          timeoutSecureDrop = setTimeout(() => {
            const selectSecure = this.shadowRoot.querySelector('[name="secure"]');
            if(selectSecure){
              selectSecure.value= "yes";
              selectSecure.dispatchEvent(new Event("change", { bubbles: true }));
            }
          }, 800);
          let timeoutScoreDrop;
          clearTimeout(timeoutScoreDrop);
          timeoutScoreDrop = setTimeout(() => {
            const selectScore = this.shadowRoot.querySelector('[name="score"]');
            selectScore.value = "Exceptional (750-850)";
            selectScore.dispatchEvent(new Event("change", { bubbles: true }));
            this.shadowRoot.querySelector('.spinner-bg').style.display = 'none';
            this.shadowRoot.querySelector('.calculator').style.display = '';
          }, 1000);
          return  this.getCurrLocation;
        } else{
          return false; 
        }
      }
      window.addEventListener('load', currentLocation );
    }
    // this.fetchStateData('Alabama').then(this.api = createApi(CALC_DATA, MAXMIN_DATA)); // I'll comment this line to test if is realy needed to load default data, we can save some exec time and server calls
  }

  manualConnect() {

    this.connectedCallback();  
    this.attachedCallback();

  }
  async connectedCallback() {
    super.connectedCallback();
    this.AVAILABLE_STATES = JSON.parse(this.getRootNode().host.querySelector('#available_states').value)
    this.MAXMIN_DATA = JSON.parse(this.getRootNode().host.querySelector('#max_min_data').value)
    this.api = createApi(this.CALC_DATA, this.MAXMIN_DATA);
    this.statesList = ['Alabama',
      'Alaska',
      'Arizona',
      'Arkansas',
      'California',
      'Delaware',
      'Florida',
      'Georgia',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'North Dakota',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Pennsylvania',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virginia',
      'Wisconsin',
      'Wyoming'];
  }

}

customElements.define(`${NAMESPACE}-calculator`, Calculator);
