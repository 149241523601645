(function () {
    getVideos();
})();

function getVideos() {
    let v = document.querySelectorAll(".oportun-image[data-yt-url]");
    for (var n = 0; n < v.length; n++) {
        let id = v[n].getAttribute("data-yt-url");

        v[n].querySelector(".image img").addEventListener("click", function () {
            var parent = this.parentNode;
            parent.classList.add("video");
            this.setAttribute('role', 'link');
            createIframe(this, id);
        });
    }
}

/**
 * Create and load iframe in Youtube container
 **/
function createIframe(v, url) {
    var iframe = document.createElement("iframe");
    iframe.setAttribute("allow", "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share");
    iframe.setAttribute("allowfullscreen", "");
    iframe.setAttribute(
        "src",
            sanitizeURL(url) +
            "?autoplay=1&color=white&autohide=2&modestbranding=1&border=0&wmode=opaque&enablejsapi=1&showinfo=0&rel=0"
    );
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("class", "youtube-iframe");
    iframe.setAttribute("referrerpolicy", "strict-origin-when-cross-origin");
    v.replaceWith(iframe);
}

function sanitizeURL(url) {
    let a = document.createElement('a');
    a.href = url;
  
    return a.protocol + "//" + a.host + a.pathname + a.search + a.hash;
  }